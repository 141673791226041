import { render, staticRenderFns } from "./test2.vue?vue&type=template&id=8c5f5052&scoped=true"
import script from "./test2.vue?vue&type=script&lang=js"
export * from "./test2.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c5f5052",
  null
  
)

export default component.exports