<template>
  <egw-table :retrieve-data="retrieveData" :export-data-file="exportServerInspectionLog"
    :export-file-name="exportFileName" :after-retrieve="afterList" :form-options="formOptions" :index="index"
    :columns="columns" :info-title="infoTitle" :drawerform="drawerform" :info-title2="infoTitle2"
    :drawerform2="drawerform2" :is-row-click="isRowClick" :param="params" :is-echart="isEchart" :serie-name="serieName"
    @handleLink="handleLink" />
</template>

<script>
import { getServerInspectionLogList, exportServerInspectionLogAsExcelFile } from '../api/ajax-server-inspection-log'
export default {
  name: 'Test2',
  data() {
    return {
      retrieveData: getServerInspectionLogList,
      exportServerInspectionLog: exportServerInspectionLogAsExcelFile,
      afterList: this.afterServerList,
      index: true,
      // 表头
      columns: [
        { prop: 'serverId', label: this.$t('serverInspectionLog.label.serverId') },
        { prop: 'name', label: this.$t('serverInspectionLog.label.name') },
        { prop: 'ipAddress', label: this.$t('serverInspectionLog.label.ipAddress') },
        { prop: 'inspectedTime', label: this.$t('serverInspectionLog.label.inspectedTime') },
        { prop: 'status', label: this.$t('serverInspectionLog.label.status'), type: 'tag' },
        { prop: 'cpuUsage', label: this.$t('serverInspectionLog.label.cpuUsage') + '(%)' },
        { prop: 'networkTraffic', label: this.$t('serverInspectionLog.label.networkTraffic') + '(MB)' },
        { prop: 'diskSpaceUsage', label: this.$t('serverInspectionLog.label.diskSpaceUsage') + '(%)' },
        { prop: 'memoryUsage', label: this.$t('serverInspectionLog.label.memoryUsage') + '(%)' },
        { prop: 'alarmType', label: this.$t('serverInspectionLog.label.alarmType') }
      ],
      // 导出的文件名
      exportFileName: '服务器巡检信息',
      // serie
      serieName: {
        cpuUsage: 'CPU使用率',
        networkTraffic: '瞬时网络流量',
        diskSpaceUsage: '存储使用率',
        memoryUsage: '内存使用率'
      },
      // 搜索
      formOptions: [
        { label: this.$t('serverInspectionLog.label.serverId'), prop: 'serverId', type: 'input' },
        {
          label: this.$t('serverInspectionLog.label.status'), prop: 'status', type: 'select',
          selectOptions: [
            { value: 1, label: '正常' },
            { value: 2, label: '异常告警' }
          ]
        },
        { label: this.$t('serverInspectionLog.label.inspectedTime'), type: 'date' }
      ],
      // 点击某一行是否显示详细信息
      isRowClick: true,
      // 详情标题
      infoTitle: this.$t('server.title.serverInfo'),
      // 第一个详情信息的标题和label
      drawerform: [
        { prop: 'serverId', label: this.$t('server.label.serverId') },
        { prop: 'name', label: this.$t('server.label.serverName') },
        { prop: 'ipAddress', label: this.$t('server.label.ipAddress') },
        { prop: 'hostName', label: this.$t('server.label.hostName') },
        { prop: 'machineType', label: this.$t('server.label.machineType') },
        { prop: 'contactId', label: this.$t('server.label.contactId') },
        { prop: 'memory', label: this.$t('server.label.memory') + '(MB)' },
        { prop: 'diskSpace', label: this.$t('server.label.diskSpace') + '(MB)', width: '240px' },
        { prop: 'inspectionPeriod', label: this.$t('server.label.inspectionPeriod') },
        { prop: 'inspectionConfig', label: this.$t('server.label.inspectionConfig'), span: 24, type: 'textarea' }
      ],
      infoTitle2: this.$t('serverInspectionLog.title.inspectionLogInfo'),
      // 第二个详情信息的标题和label
      drawerform2: [
        { prop: 'id', label: this.$t('serverInspectionLog.label.id') },
        { prop: 'inspectedTime', label: this.$t('serverInspectionLog.label.inspectedTime') },
        { prop: 'cpuUsage', label: this.$t('serverInspectionLog.label.cpuUsage') + '(%)' },
        { prop: 'networkTraffic', label: this.$t('serverInspectionLog.label.networkTraffic') },
        { prop: 'diskSpaceUsage', label: this.$t('serverInspectionLog.label.diskSpaceUsage') + '(%)' },
        { prop: 'usedDiskSpace', label: this.$t('serverInspectionLog.label.usedDiskSpace') + '(MB)', span: 24 },
        { prop: 'memoryUsage', label: this.$t('serverInspectionLog.label.memoryUsage') + '(%)' },
        { prop: 'usedMemory', label: this.$t('serverInspectionLog.label.usedMemory') + '(MB)', span: 24 },
        { prop: 'status', label: this.$t('serverInspectionLog.label.status'), type: 'tag' },
        { prop: 'alarmType', label: this.$t('serverInspectionLog.label.alarmType'), span: 24 },
        { prop: 'alarmMessage', label: this.$t('serverInspectionLog.label.alarmMessage'), span: 24 }
      ],
      // 传递控制图表显示的参数
      params: undefined,
      isEchart: false
    }
  },
  created() {
    if (JSON.stringify(this.$route.query) === '{}') {
      console.log('路由没有参数')
    } else {
      this.params = this.$route.query
      this.isEchart = true
      this.formOptions = [{ label: this.$t('serverInspectionLog.label.inspectedTime'), type: 'date' }]
    }
  },
  methods: {
    afterServerList(list) {
      list.forEach(item => {
        item = this.changeStatus(item)
        item = this.changeAlarmType(item)
        item.inspectedTime = this.changeTime(item.inspectedTime)
      })
      console.log(list)
      return list
    },
    /**
    * @methods changeStatus
    * @description 转换巡检状态为i18n对应的字段
    * @param {Object} params 包含巡检状态的数据
    */
    changeStatus(item) {
      if (item.status === 1) {
        item.status = this.$t('serverInspectionLog.status.normal')
        item.type = ''
      } else if (item.status === 2) {
        item.status = this.$t('serverInspectionLog.status.anomalous')
        item.type = 'danger'
      }
      return item
    },
    /**
    * @methods changeAlarmType
    * @description 将告警类型转换为对应i8n的字段
    * @param {Object} params 包含告警类型的数据
    */
    changeAlarmType(item) {
      if (item.alarmType === 'CPU_USAGE_TOO_HIGH') {
        item.alarmType = this.$t('serverInspectionLog.alarmType.cpuUsage')
      } else if (item.alarmType === 'MEMORY_USAGE_TOO_HIGH') {
        item.alarmType = this.$t('serverInspectionLog.alarmType.memory')
      } else if (item.alarmType === 'ABNORMAL_NETWORK_TRAFFIC') {
        item.alarmType = this.$t('serverInspectionLog.alarmType.networkTraffic')
      } else if (item.alarmType === 'ABNORMAL_DISK_IO') {
        item.alarmType = this.$t('serverInspectionLog.alarmType.diskIO')
      }
      return item
    },
    handleLink(data) {
      const id = data.serverId
      // this.$router.go(0)
      this.$router.replace({
        path: '/empty',
        query: {
          router: '/test2?serverId=' + id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
